/* eslint-disable max-lines */
/* eslint-disable fp/no-loops,fp/no-let */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PAGE_SIZE } from 'Component/ProductList/ProductList.config';
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import {
    APPEND_LAST_LOCAL_PAGE,
    APPEND_PAGE,
    RESET_STATE,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PAGE_NUMBER_TO_SHOW,
    UPDATE_PRODUCT_LIST_ITEMS
} from 'Store/ProductList/ProductList.action';
import { getIndexedProducts } from 'Util/Product';

/** @namespace Scandipwa/Store/ProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    pages: {},
    futurePages: {},
    promoTiles: [],
    promoTilePosition: 0,
    totalItems: 0,
    totalPages: 0, // Pages to show =  products + promo tiles / page size
    totalPagesOnServer: 0, // Pages that server has product data for, stuff for us to request
    isLoading: true,
    currentArgs: {},
    pagesNumberToShow: 1
});

export const defaultConfig = {
    itemsPerPageCount: PAGE_SIZE
};

/**
 * Define the chunk method in the prototype of an array
 * that returns an array with arrays of the given size.
 *
 * @param chunkSize {Integer} Size of every group
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'chunk', {
    value(chunkSize) {
        const temporal = [];

        for (let i = 0; i < this.length; i += chunkSize) {
            temporal.push(this.slice(i, i + chunkSize));
        }

        return temporal;
    }
});

/** @namespace Scandipwa/Store/ProductList/Reducer/mergeProductItems */
export const mergeProductItems = (products, promoTiles, tilePosition) => {
    const result = [];
    let productIndex = 0;
    let promoTileIndex = 0;
    let itemToAdd = null;

    for (let i = 0; i < PAGE_SIZE; i++) {
        itemToAdd = null;

        if ((i + 1) % tilePosition === 0) {
            itemToAdd = (promoTiles && typeof promoTiles[promoTileIndex] !== 'undefined')
                ? promoTiles[promoTileIndex] : null;
            promoTileIndex++;
        }

        if (!itemToAdd) {
            itemToAdd = (products && typeof products[productIndex] !== 'undefined')
                ? products[productIndex] : null;
            productIndex++;
        }

        if (!itemToAdd) {
            return result;
        }

        result.push(itemToAdd);
    }

    return result;
};

// TODO Improvement: Fetch promo tiles only once for a category and store in a state

/** @namespace Scandipwa/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action = {}
) => {
    const {
        type,
        config: {
            storeConfig: {
                plp_promo_tile_position = 0
            } = {}
        } = {},
        promo_tile = [],
        items: initialItems = [],
        total_count,
        total_pages: totalPagesOnServer,
        currentPage,
        isLoading,
        args: currentArgs,
        pagesNumberToShow
    } = action;

    const { promoTilePosition, futurePages } = state;
    const maxTilesPerPage = Math.floor(PAGE_SIZE / promoTilePosition);
    const fallbackPromoTiles = type === UPDATE_PRODUCT_LIST_ITEMS ? [] : state.promoTiles;
    const promoTiles = (promo_tile && promo_tile.length > 0) ? promo_tile.chunk(maxTilesPerPage) : fallbackPromoTiles;
    const currentPageIndex = currentPage - 1;

    const calculateTilesOnLastPage = (productCount) => {
        let itemsCount = productCount;
        let tilesCount = 0;

        for (let i = 0; i <= itemsCount; i++) {
            if ((i + 1) % promoTilePosition === 0) {
                itemsCount++;
                tilesCount++;
            }
        }

        return tilesCount;
    };

    const shiftExtraItemsToNextPage = (itemsList) => {
        if (!promoTiles.length
            || !promoTiles[currentPageIndex]
            || !promoTiles[currentPageIndex].length
        ) {
            const itemsOverPageSize = itemsList.length - PAGE_SIZE;

            if (itemsOverPageSize > 0) {
                return itemsList.splice(-itemsOverPageSize);
            }

            return [];
        }

        const maxTilesOnCurrentPage = Math.floor(itemsList.length / (promoTilePosition - 1));
        const tilesOnCurrentPage = Math.min(maxTilesOnCurrentPage, promoTiles[currentPageIndex].length);
        const itemsForCurrentPage = tilesOnCurrentPage + itemsList.length;
        const itemsToShift = itemsForCurrentPage - PAGE_SIZE;

        if (itemsToShift > 0) {
            return itemsList.splice(-itemsToShift);
        }

        return [];
    };

    switch (type) {
    case APPEND_PAGE:
        const futurePage = futurePages[currentPage] || [];
        const itemsForPage = [...futurePage, ...getIndexedProducts(initialItems)];
        const pageItemsForFuturePage = shiftExtraItemsToNextPage(itemsForPage);

        return {
            ...state,
            isPageLoading: false,
            pages: {
                ...state.pages,
                [currentPage]: mergeProductItems(itemsForPage, promoTiles[currentPageIndex], promoTilePosition)
            },
            futurePages: {
                ...futurePages,
                [currentPage + 1]: pageItemsForFuturePage
            }
        };

    case APPEND_LAST_LOCAL_PAGE:
        return {
            ...state,
            isPageLoading: false,
            pages: {
                ...state.pages,
                // eslint-disable-next-line max-len
                [currentPage]: mergeProductItems(futurePages[currentPage], promoTiles[currentPageIndex], promoTilePosition)
            },
            futurePages: {}
        };

    // Will save promo tile config for later use in PLP
    case UPDATE_CONFIG:
        return {
            ...state,
            // eslint-disable-next-line no-magic-numbers
            promoTilePosition: plp_promo_tile_position || 999
        };

    case UPDATE_PRODUCT_LIST_ITEMS:
        const pageItems = getIndexedProducts(initialItems);
        const pageItemsForNextPage = shiftExtraItemsToNextPage(pageItems);

        const maxTilesOnFullPage = Math.floor(PAGE_SIZE / promoTilePosition);
        const fullPageCount = Math.floor(total_count / PAGE_SIZE);
        const itemsOnLastPage = total_count + (maxTilesOnFullPage * fullPageCount) - (fullPageCount * PAGE_SIZE);
        const maxTilesOnLastPage = calculateTilesOnLastPage(itemsOnLastPage);
        const maxTilesCount = (maxTilesOnFullPage * fullPageCount) + maxTilesOnLastPage;
        const tilesCount = Math.min(maxTilesCount, promo_tile ? promo_tile.length : 0);
        const totalItems = total_count + tilesCount;
        const totalPages = Math.ceil(totalItems / PAGE_SIZE);

        return {
            ...state,
            currentArgs,
            isLoading: false,
            totalItems,
            totalPages,
            totalPagesOnServer,
            pages: {
                [currentPage]: mergeProductItems(pageItems, promoTiles[currentPageIndex], promoTilePosition)
            },
            futurePages: {
                [currentPage + 1]: pageItemsForNextPage
            },
            promoTiles
        };

    case UPDATE_PAGE_LOAD_STATUS:
        return {
            ...state,
            isPageLoading: true
        };

    case UPDATE_LOAD_STATUS:
        return {
            ...state,
            isLoading
        };

    case UPDATE_PAGE_NUMBER_TO_SHOW:
        return {
            ...state,
            pagesNumberToShow
        };

    case RESET_STATE:
        return {
            ...state,
            pages: {}
        };

    default:
        return state;
    }
};

export default ProductListReducer;
